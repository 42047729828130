h2 {
  color: #f5bb07;
}
.form-block {
  box-shadow: gray;
}
.button-view {
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-primary {
  background-color: #f5bb07 !important;
  color: white !important;
}
.form-control {
  background-color: #f4f3f3 !important;
}
.form-label {
  font-weight: bold !important;
}



.line_clamp_1{
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.line_clamp_2{
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.line_clamp_3{
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.line_clamp_4{
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
}