@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;


* {
  font-family: "Poppins";
  scroll-behavior: smooth;
}
body {

  margin: 0;
  font-family: 'Poppins', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bg-yellow-450 {
  background-color: #F5BB07;

}

.activeNavLinkAnimation {
  animation: ActiveNavAnimate 300ms linear forwards;
}

@keyframes ActiveNavAnimate {
  0% {
    right: 100%;
  }

  100% {
    right: 0;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.hide-input-arrow-button::-webkit-inner-spin-button,
.hide-input-arrow-button::-webkit-outer-spin-button {
  display: none;
}

.pentutor-shadow {
  box-shadow: 0px 0px 20px 0px #dfdfdf79;
  -webkit-box-shadow: 0px 0px 20px 0px #dfdfdf79;
  -moz-box-shadow: 0px 0px 20px 0px #dfdfdf79;
}

.sidebar-shadow {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.footer-gray {
  background-color: #404040;
}

.color-green {
  background-color: #01A62F;

  ;
}

.slide-right {
  background-color: red !important;
}

.w-110 {
  width: 27rem;
}

.lg:w-110 {
  width: 27rem;
}

.h-110 {
  height: 27rem;
}

.lg:h-110 {
  height: 27rem;
}